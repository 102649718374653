// Main entry point for member javascript

import Turbolinks from 'turbolinks';
import { stimulus } from 'init/init'; // common imports and app initialization
import { definitionsFromContext } from 'init/stimulus/stimulus';

import { injectVisit } from 'lib/visit';

// Automatically import all Stimulus controllers except under admin
stimulus.load(
  definitionsFromContext(
    require.context('../components', true, /^(?!.*admin\/).*_controller\.js$/),
  ),
);
stimulus.load(
  definitionsFromContext(
    require.context('../pages', true, /^(?!.*admin\/).*_controller\.js$/),
  ),
);

// Turbolinks app startup
//
// NOTE: Turbolinks is not used in the admin app as the minor speedup it provides
// is not likely worth the added effort for supporting the more complex admin site.
// As a result, any Turbolinks method used in member code must be injected here,
// since the admin entry point imports all member code.  See for example the
// lib/visit module configured below.
Turbolinks.start();

// Turbolinks configuration

// Clear cache when Rails UJS ajax events occur since they frequently change
// server state
$(document).on('ajax:before', '[data-remote]', () => {
  Turbolinks.clearCache();
});

// Configure the visit method to use Turbolinks.visit.
injectVisit(Turbolinks.visit);
